import React from "react";

import "./aboutUsStyle.scss";
import logo from "../../assets/icons/logo-white.svg";
import AboutUsContent from "../../components/aboutUsContent/aboutUsContent";
import Owner from "../../components/owner/owner";
// import OwnerHistory from "../../components/owner/ownerHistory";
import Partner from "../../components/partners/partner";
import News from "../../components/news/news";
import SubNavigation from "../../components/SubNavigation";

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <SubNavigation
        headerPage={"INSTITUCIONAL"}
        isAboutUs={true}
        isTalent={false}
        pageSelected="aboutUs"
      />
      <div className="mainBanner">
        <div className="context">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <AboutUsContent />
      <Owner />
      {/* <div className="h160"></div>
            <OwnerHistory /> */}
      <Partner />
    </div>
  );
};

export default AboutUs;
