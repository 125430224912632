import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
`;

export const ModalContainer = styled.div`
  position: relative;
  width: 600px;
  background: white;
  padding: 30px;
  border-radius: 8px;
`;

export const ModalBackground = styled.div`
  background: #0d044d;
  color: white;
  border-radius: 8px;
`;

export const TextContainer = styled.div`
  padding: 40px;
  color: white;
  font-family: Lexend;
  p:first-child {
    font-size: 24px;
  }
  p {
    font-size: 32px;
  }
  span {
    font-weight: bold;
  }

  button {
    font-family: Lexend;
    background: #2c1dfe;
    color: white;
    font-size: 22px;
    padding: 14.5px 8px;
    border-radius: 4px;
  }

  @media (max-width: 560px) {
    padding: 20px;

    p:first-child {
      font-size: 18px;
    }
    p {
      font-size: 24px;
    }
    button {
      font-size: 18px;
    }
  }

  @media (max-width: 380px) {
    padding: 10px;

    p:first-child {
      font-size: 14px;
    }
    p {
      font-size: 20px;
    }
  }
`;

export const PersonalitiesContainer = styled.div`
  position: relative;
  display: flex;
  margin-top: 50px;

  @media (max-width: 430px) {
    margin-top: 50px;
  }

  @media (max-width: 380px) {
    margin-top: 50px;
  }
`;

export const PersonalitiesBackground = styled.img`
  width: 100%;
  object-fit: cover;
  height: 250px;
  border-radius: 8px;

  @media (max-width: 560px) {
    height: 180px;
  }
  @media (max-width: 420px) {
    height: 140px;
  }
`;

export const PersonalitiesImage = styled.img`
  width: 88%;
  position: absolute;
  bottom: 0;
  left: 40px;
  pointer-events: none;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 38px;
  right: 38px;
  cursor: pointer;
  font-size: 2rem;
`;
