import React from "react";

import './steps.scss';
import LabelBG from "../label/labelBG";
import LabelW300H40 from "../label/labelW300H40";
// import LabelW300H14 from "../label/labelW300H14";
import TextButton from "../TextButton";
import arrowIcon from "../../assets/icons/circle-arrow-right-blue.svg";

const Steps = () => {
  const goStore = (url: string) => {
    if (url === 'you')
      window.open('https://www.youtube.com/@plataformaoceano', '_blank');
  }

  return (
    <div className="steps">
      <div className="descriptions">
        <div className="principal">
          <div className="bgMax">
            <LabelBG text={"PASSO A PASSO"} />
          </div>
          <div className="descMax">
            <LabelW300H40 text={"Canal com guias exclusivos sobre como conseguir ter mais oportunidades de trabalho"} />
          </div>
        </div>
        <TextButton
          text="SE INSCREVA NO NOSSO CANAL"
          color="#090633"
          icon={arrowIcon}
          onClick={() => goStore('you')}
        />
      </div>
      
    </div>
  );
}

export default Steps;
