import React, { useEffect, useState } from "react";
import ana from '../../assets/images/anaOwner.webp';
import cleber from '../../assets/images/CleberOwner.webp';
import './owner.scss';
import OwnerDescription from "./ownerDescription";
import ownerContent from '../../data/data.json';
import quoteIcon from '../../assets/icons/quote-left.svg';
import LabelW300H32Write from "../label/labelW300H32Write";
import LabelW500H24 from "../label/labelW500H24";
import LabelW300H12 from "../label/labelW300H12";

interface ow {
  id: number;
  text: string;
  name: string;
  description: string;
}

const OwnerCard = () => {
  const [owner, ownerSet] = useState<ow>({ id: 0, text: '', name: '', description: '' });

  useEffect(() => {
    ownerSet(ownerContent.ownerContent[0]);
  }, []);

  return (
    <div className="ownerCard">
        <img src={cleber} className="picture" alt="cleber" />
      <div className="areaImage">
        <img src={cleber} className="picture" alt="cleber" />
        <div className="ownerDescription">
          <LabelW500H24 text={owner.name} isBlack={false} />
          <div className="h24"></div>
          <LabelW300H12 text={owner.description} isWhite={true} />
        </div>
      </div>
      <div className="descriptionMobile">
        <LabelW300H32Write text={owner.text} />
      </div>
      <div className="areaIcon">
        <img src={quoteIcon} alt="quote-icon" />
      </div>
      <div className="ownerAbourt">
        <OwnerDescription text={owner.text} name={owner.name} description={owner.description} />
      </div>
    </div>
  );
}

export default OwnerCard;
