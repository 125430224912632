import React from "react";

import "./owner.scss";
import LabelW300S40 from "../label/labelW300S40";
import OwnerCard from "./ownerCard";

const Owner = () => {
  return (
    <div className="owner">
      <LabelW300S40 text={"Depoimentos"} />
      <div className="ownerCards">
        <OwnerCard />
      </div>
    </div>
  );
};

export default Owner;
