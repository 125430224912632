import React from "react";

import anaMobile from "../../assets/images/anaMobile.webp";
import google from "../../assets/images/google.webp";
import apple from "../../assets/images/apple.webp";
import checkIcon from "../../assets/icons/check-icon.svg";

import "./bannerStyle.scss";
import LabelTitelW300H32 from "../label/labelTitelW300H32";
import LabelW300H20 from "../label/labelW300H20";
import { width } from "@fortawesome/pro-light-svg-icons/faCircleCheck";

const BannerDownloadApp = () => {
  const goStore = (url: string) => {
    if (url === "google")
      window.open(
        "https://play.google.com/store/apps/details?id=oceano.mobile1233",
        "_blank"
      );
    else if (url === "apple")
      window.open(
        "https://apps.apple.com/br/app/oceano/id1629763551",
        "_blank"
      );
  };

  return (
    <div className="backgrounBannerDownloadApp">
      <div className="bannerDownloadApp">
        {/* <img src={anaMobile} className="image" alt="ana" /> */}
        <div className="image"></div>
        <div className="informations">
          <div className="header">
            <LabelTitelW300H32
              text={""}
              text2={"e comece a buscar os melhores trabalhos!"}
              span={"Baixe agora o aplicativo "}
              isSameLane={false}
              text3={""}
            />
          </div>
          <div className="descriptions">
            <div className="area-description">
              <div className="sub-title">
                <img src={checkIcon} alt="check" />
                <LabelW300H20
                  text={"Simplificamos o trabalho para você"}
                  center={false}
                />
              </div>
              <LabelW300H20
                text={
                  "Receber, avaliar e gerenciar propostas nunca foi tão fácil. Nosso aplicativo permite que você controle o fluxo de trabalhos de acordo com sua preferência e disponibilidade, tornando o processo simples e ágil."
                }
                center={false}
              />
            </div>
            <div className="area-description">
              <div className="sub-title">
                <img src={checkIcon} alt="check" />
                <LabelW300H20
                  text={"Segurança em primeiro lugar"}
                  center={false}
                />
              </div>
              <LabelW300H20
                text={
                  "Na Oceano, priorizamos a segurança. Todos os pagamentos são efetuados em nossa plataforma 100% segura, proporcionando proteção total para criadores, agências e marcas."
                }
                center={false}
              />
            </div>
            <div className="area-description">
              <div className="sub-title">
                <img src={checkIcon} alt="check" />
                <LabelW300H20
                  text={"Suporte personalizado à sua disposição!"}
                  center={false}
                />
              </div>
              <LabelW300H20
                text={
                  "Nossa equipe de suporte está aqui para você. Se surgir alguma dúvida, conte conosco para garantir que você tenha a melhor experiência possível em nosso aplicativo. Estamos sempre disponíveis para ajudar no que você precisar."
                }
                center={false}
              />
            </div>
          </div>
          <div className="stores">
            <img src={google} alt="" onClick={() => goStore("google")} />
            <img src={apple} alt="" onClick={() => goStore("apple")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerDownloadApp;
