import React, { SetStateAction, useEffect } from "react";
import { CSSObject } from "styled-components";
import {
  Overlay,
  ModalContainer,
  CloseButton,
  TextContainer,
  PersonalitiesContainer,
  ModalBackground,
  PersonalitiesBackground,
} from "./styles";
import closeIcon from "../../assets/icons/circle-close-modal.svg";
import modalPersonalities from "../../assets/images/modalPersonalities2.png";

interface ModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  style?: CSSObject;
}

const HomeModal = ({ isOpen, setIsOpen, style }: ModalProps) => {
  // Função para verificar se já passou 24 horas desde a última exibição
  const shouldShowModal = () => {
    const lastClosed = localStorage.getItem("modalLastClosed");

    if (!lastClosed) return true; // nunca foi exibido

    const lastClosedDate = new Date(lastClosed);
    const currentDate = new Date();

    // Checar se já passou 24 horas
    return (
      currentDate.getTime() - lastClosedDate.getTime() > 24 * 60 * 60 * 1000
    );
  };

  // Exibir o modal automaticamente se já tiver passado o tempo
  useEffect(() => {
    if (shouldShowModal()) {
      setIsOpen(true);
    }
  }, [setIsOpen]);

  // Função para fechar o modal e salvar a data no localStorage
  const handleCloseModal = () => {
    setIsOpen(false);
    localStorage.setItem("modalLastClosed", new Date().toISOString());
  };

  // Fechar o modal ao clicar fora dele
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  const openLink = (link?: string) => {
    if (link) window.open(link, "_blank");
  };
  if (!isOpen) return null;

  return (
    <Overlay onClick={handleOverlayClick}>
      <ModalContainer style={style}>
        <ModalBackground
          style={{
            background: "#0d044d",
            color: "white",
            borderRadius: "8px",
          }}
        >
          <CloseButton
            onClick={handleCloseModal}
            style={{ marginLeft: "auto" }}
          >
            <img src={closeIcon} alt="Fechar modal" />
          </CloseButton>
          <TextContainer>
            <p>#DoOnParaOff</p>
            <p>
            Se você já brilha no digital, agora é a  
            <span> sua chance de aparecer na televisão!</span>
            </p>
            <button
              onClick={() =>
                openLink(" https://chat.whatsapp.com/JPjsfgOaOOSG3s4QP809HE")
              }
            >
              Saiba mais
            </button>
          </TextContainer>

          <PersonalitiesContainer>
          <PersonalitiesBackground
              src={modalPersonalities}
              alt="personalites-background"
            />
          </PersonalitiesContainer>
        </ModalBackground>
      </ModalContainer>
    </Overlay>
  );
};

export default HomeModal;
